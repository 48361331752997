import React from 'react'
import {Button} from 'antd';
import "./ticketSubmit.css"
const TicketSubmit = ({ load,type, name, value, onChange, placeholder, ticketWashSubmit, error ,btnshow,ticketWashClose}) => {
    return (
        <div className="wash_certificate_ticket_submit">
            <div>
            <input type={type} name={name} value={value} onChange={onChange} placeholder={placeholder} autoComplete="off"/>
                {btnshow ? 
                <Button type="primary" onClick={ticketWashClose} style={{ display: 'flex', alignItems: "center", justifyContent: 'center', height: "27.5px", borderRadius: "5px",background:'#BE0F24' }} danger>Close</Button>
                : <Button style={{ display: 'flex', alignItems: "center", justifyContent: 'center', height: "27.5px", borderRadius: "5px",background:'#38B6FF',color:'#fff' }} onClick={ticketWashSubmit}>Save</Button>}
            </div>
            <span style={{color:"red"}}>{error}</span>
		</div>
    )
}

export default TicketSubmit
